import Section from "./Section";

import ApiSubscriptionStateComponent from "../../../atoms/api-subscription-state-component/ApiSubscriptionStateComponent";

import { SANDBOX } from "../../../utils/GlobalConsts";
import { SUBSCRIBED } from "../../../utils/GlobalConsts";

const APISubscriptionSection = ({
  formData,
  handleChange,
  currentEnv,
  placeholders,
}) => {
  return (
    <Section>
      <Section.Title>API Subscription</Section.Title>
      <div className="request-modification-tab-content__sections__section__inputs">
        <div className="request-modification-tab-content__sections__section__description sm input-content">
          <span>APIs subscribed</span>
        </div>
        <div className="request-modification-tab-content__sections__section__checkbox input-content">
          <div className="input-content__checkboxes__checkbox">
            <input
              type="checkbox"
              name="apiIso"
              id="apiIso"
              checked={formData.subscribeAPI.apiIso === SUBSCRIBED}
              value={formData.subscribeAPI.apiIso === SUBSCRIBED}
              onChange={handleChange}
              disabled={formData.environment === SANDBOX}
            />
            <label htmlFor="apiIso">
              <div className="input-content__checkboxes__checkbox__content">
                <div className="input-content__checkboxes__checkbox__text">
                  <div className="input-content__checkboxes__checkbox__text__title">
                    <span>API ISO20022</span>
                  </div>
                  <div className="input-content__checkboxes__checkbox__text__description">
                    <span>Brief description of the API utility</span>
                  </div>
                  <div className="input-content__checkboxes__checkbox__subscription">
                    <ApiSubscriptionStateComponent
                      state={formData.subscribeAPI.apiIso}
                    />
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>

        {currentEnv !== "SANDBOX" && (
          <div className="request-modification-tab-content__sections__section__inputs request-modification-tab-content__sections__section__inputs--api-info input-content">
            <div className="input-content">
              <div className="input-content__title">
                <span>Public Key</span>
              </div>
              <label>
                <textarea
                  name="publicKey"
                  value={formData.publicKey}
                  placeholder={placeholders.publicKey}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Key ID</span>
              </div>
              <label>
                <input
                  type="text"
                  name="keyId"
                  value={formData.keyId}
                  placeholder={placeholders.keyId}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="input-content">
              <div className="input-content__title">
                <span>Issuer</span>
              </div>
              <label>
                <input
                  type="text"
                  name="insertIssuer"
                  value={formData.insertIssuer}
                  placeholder={placeholders.insertIssuer}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default APISubscriptionSection;
