const FormRequestSubscriptionInputContent = ({ children }) => {
  return <div className="input-content">{children}</div>;
};

const FormRequestSubscriptionInput = ({
  number,
  title,
  className,
  preContentSlot = undefined,
  children,
}) => {
  return (
    <div
      className={`request-subscription__form__input ${
        className ? className : ""
      }`.trim()}
    >
      <div className="request-subscription__form__input__title">
        <span className="request-subscription__form__input__title__number">
          {number}
        </span>
        <h4>{title}</h4>
      </div>
      {preContentSlot && preContentSlot}
      <div className="request-subscription__form__input__content">
        {children}
      </div>
    </div>
  );
};

FormRequestSubscriptionInput.Content = FormRequestSubscriptionInputContent;

export default FormRequestSubscriptionInput;
