const SectionDescription = ({ children }) => {
  return (
    <div className="request-modification-tab-content__sections__section__description input-content">
      <span>{children}</span>
    </div>
  );
};

const SectionTitle = ({ children }) => {
  return (
    <div className="request-modification-tab-content__sections__section__title">
      <h4>{children}</h4>
    </div>
  );
};

const Section = ({ children, className }) => {
  return (
    <section
      className={`request-modification-tab-content__sections__section ${
        className ? className : ""
      }`.trim()}
    >
      {children}
    </section>
  );
};

Section.Description = SectionDescription;
Section.Title = SectionTitle;

export default Section;
