const EnvironmentInputRadio = ({
  value,
  name,
  id,
  htmlFor,
  checked,
  onChange,
  title,
  description,
  children,
}) => {
  return (
    <>
      <input
        type="radio"
        value={value}
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={htmlFor}>
        <div className="input-content__radios__radio">
          <div className="input-content__radios__radio__content">
            <span className="input-content__radios__radio__title">{title}</span>
            <span className="input-content__radios__radio__description">
              {description}
            </span>
            <div className="input-content__radios__radio__button">
              {children}
            </div>
          </div>
        </div>
      </label>
    </>
  );
};

export default EnvironmentInputRadio;
