import FormRequestSubscriptionInput from "./FormRequestSubscriptionInput";

const EnvironmentDataSection = ({ formData, placeholders, handleChange }) => {
  return (
    <FormRequestSubscriptionInput number={4} title="Provide us your data">
      <FormRequestSubscriptionInput.Content>
        <div className="input-content__title">
          <span>Public Key</span>
        </div>
      </FormRequestSubscriptionInput.Content>
      <FormRequestSubscriptionInput.Content>
        <div className="input-content__title">
          <span>Key ID</span>
        </div>
        <label>
          <input
            type="text"
            name="keyId"
            value={formData.keyId}
            placeholder={placeholders.keyId}
            autoComplete="off"
            onChange={handleChange}
          />
        </label>
      </FormRequestSubscriptionInput.Content>
      <FormRequestSubscriptionInput.Content>
        <div className="input-content__title">
          <span>Issuer</span>
        </div>
        <label>
          <input
            type="text"
            name="insertIssuer"
            value={formData.insertIssuer}
            placeholder={placeholders.insertIssuer}
            autoComplete="off"
            onChange={handleChange}
          />
        </label>
      </FormRequestSubscriptionInput.Content>
    </FormRequestSubscriptionInput>
  );
};

export default EnvironmentDataSection;
