export const customAuthConfig = {
  /* Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      loginWith: {
        // Optional
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN,
          scopes: ["openid email phone profile aws.cognito.signin.user.admin "],
          redirectSignIn: ["https://pagonext-pro.auth.eu-west-1.amazoncognito.com", "http://localhost:3000", "https://frontend.pagonxt-pro.garajedeideas.es"],
          redirectSignOut: ["https://pagonext-pro.auth.eu-west-1.amazoncognito.com", "http://localhost:3000", "https://frontend.pagonxt-pro.garajedeideas.es"],
          responseType: "code",
        },
        username: "true",
        email: "false", // Optional
        phone: "false", // Optional
      },
    },
  }, */

  authority: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_mVcHB7CKD",
  client_id: "2gd64ed1bb5oc8q6f7puj68afv",
  redirect_uri: "https://private.developer.pagonxtpayments.com/",
  response_type: "code", 
  scope: "email openid phone profile",
};
