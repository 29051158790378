//DEV
export const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID_DEV , 
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID_DEV
 };

// PRE
/* export const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID_PRO,
    ClientId: process.env.REACT_APP_AWS_CLIENT_ID_PRO 
}; */

// PRO
/* export const poolData = {
  UserPoolId: process.env.AWS_USER_POOL_ID || 'eu-west-1_mVcHB7CKD',
  ClientId: process.env.AWS_CLIENT_ID || '2gd64ed1bb5oc8q6f7puj68afv'
};
 */