import Section from "./Section";
import AlertComponent from "../../../atoms/alert-component/alertComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";

const PendingRequestSection = ({ handleCancel }) => {
  return (
    <Section className="request-modification-tab-content__sections__section--pending-request">
      <Section.Title>Pending Request</Section.Title>
      <div className="request-modification-tab-content__sections__section__alert">
        <AlertComponent
          text={
            "You have an active request. If you need to make a modification, cancel this request."
          }
          bgColor={"bg--brand-warning-100"}
        />
      </div>
      <div className="request-modification-tab-content__sections__section__button">
        <ButtonComponent
          onClick={handleCancel}
          bgColor={"button-component--bg--warning-500"}
          text={"Cancel this request"}
        />
      </div>
    </Section>
  );
};

export default PendingRequestSection;
