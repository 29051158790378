import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { success as showSuccess } from "../../../utils/NotificationsContainer";

import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import SearchComponent from "../../../molecules/search-component/SearchComponent";
import SelectFilterComponent from "../../../molecules/select-filter-component/SelectFilterComponent";
import DateFilterComponent from "../../../molecules/date-filter-component/DateFilterComponent";

import { getUsersList } from "../../../../services/ApiUsers";
import { EDIT_USERS } from "../../../utils/Routes";

import { useGlobalVar } from "../../../utils/GlobalContext";
import useTableData from "../../../../hooks/admin-area/useTableData";

const headerUsers = [
  "Organization",
  "Name",
  "Lastname",
  "Email",
  "Registration",
  "Role",
];

const headerToFieldMap = {
  Organization: "organization",
  Name: "name",
  Lastname: "lastname",
  Email: "email",
  Registration: "date",
  Role: "role",
};

const columnWidths = ["15%", "20%", "20%", "25%", "20%", "15%"];

const ManageUsers = ({ mainPage }) => {
  const { accessToken } = useGlobalVar();
  const [requestData, setRequestData] = useState([]);
  const {
    currentPage,
    itemsPerPage,
    searchTerm,
    startDate,
    endDate,
    sortColumn,
    sortOrder,
    filterType,
    handleSearch,
    handleSort,
    handleDateRangeChange,
    handleFilterTypeChange,
    handleItemsPerPageChange,
    handlePageChange,
    getSortedData,
  } = useTableData();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersListData = await getUsersList(accessToken);

        // Asegúrate de que mapeas las propiedades correctamente
        const mappedData = usersListData.map((item) => ({
          organization: item.organization,
          name: item.users?.firstname || "", // Comprueba si users existe
          lastname: item.users?.lastname || "", // Comprueba si users existe
          email: item.users?.email || "", // Comprueba si users existe
          date: item.users?.date || "", // Comprueba si users existe
          role: item.users?.roles[0]?.name || "", // Comprueba si roles existe
          phone: item.users?.phone || "", // Comprueba si roles existe
          id: item.users?.id || "", // Comprueba si roles existe
        }));

        setRequestData(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken]);

  const requestRoleFilter = [
    ...new Set(requestData.map((request) => request.role)),
  ];

  const handleRowClick = (rowIndex) => {
    const rowData = currentData[rowIndex];

    navigate(EDIT_USERS, { state: { rowData } });
  };

  const getFilteredAndSortedData = () => {
    let data = Array.isArray(requestData) ? [...requestData] : [];

    data = data.filter((item) => {
      const itemDate = new Date(item.date);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      const termToFilter = searchTerm.trim().toLowerCase();
      return (
        (!filterType || item.role === filterType) &&
        (item.name.toLowerCase().includes(termToFilter) ||
          ((item.email.toLowerCase().includes(termToFilter) ||
            item.organization?.toLowerCase().includes(termToFilter) ||
            false) && // Verificación segura de organization
            (!start || itemDate >= start) &&
            (!end || itemDate <= end)))
      );
    });

    const sortedAndFilteredData = getSortedData({ data, headerToFieldMap });

    return sortedAndFilteredData;
  };

  const filteredAndSortedData = getFilteredAndSortedData();

  const currentData = filteredAndSortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredAndSortedData.length / itemsPerPage);

  useEffect(() => {
    const reloadDeleted = localStorage.getItem("reloadDeleted");

    if (reloadDeleted) {
      showSuccess(`User successfully deleted`);
      localStorage.removeItem("reloadDeleted");
    }
  }, []);

  return (
    <div className="request-subscription">
      <div className="request-subscription__heading mg mg--t-4">
        {!mainPage ? (
          <div className="request-subscription__heading__back-button">
            <BackButtonComponent />
          </div>
        ) : null}

        <h1>User List</h1>
      </div>
      <div className="request-subscription__form">
        <div className="request-management__actions">
          <SearchComponent onSearch={handleSearch} />
          <div className="request-management__filters">
            <SelectFilterComponent
              placeholder="User role"
              options={requestRoleFilter}
              onChange={handleFilterTypeChange}
              className="select-filter"
            />
            <DateFilterComponent onDateRangeChange={handleDateRangeChange} />
          </div>
        </div>
        <div className="mg mg--t-2">
          <TableComponent
            headers={headerUsers}
            headerToFieldMap={headerToFieldMap}
            data={currentData}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onRowClick={handleRowClick}
            combineName={true}
            showBtn={false}
            columnWidths={columnWidths}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
