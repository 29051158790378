import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
} from "amazon-cognito-identity-js";
import { error as showError } from "../../utils/NotificationsContainer";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import InputFormComponent from "../../form/InputFormComponent";
import PasswordInput from "../../form/PasswordInput";
import { login } from "../../../api/api";
import { useGlobalVar } from "../../utils/GlobalContext";
import { useApiCall } from "../../../hooks/useApiCall";
import { Outlet, useNavigate } from "react-router-dom";
import { poolData } from "../../../cognito/cognito";
import { useAuth } from "react-oidc-context";

import { FORGOT_PASSWORD, REGISTER, ADD_PASSWORD, HOME, REQUESTS_MANAGEMENT } from "../../utils/Routes";

const LoginPage = () => {
	const auth = useAuth();
	const {
		setLoggedIn,
		setSessionId,
		setAccessToken,
		setUserEmail,
		isAuth,
		role
	} = useGlobalVar();
	const navigate = useNavigate();

	const [hasLoggedIn, setHasLoggedIn] = useState(false);
	const [error, setError] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [, , loginCall] = useApiCall((token) => login(token), {
		executeImmediately: false,
		onSuccess: (data) => {
			setSessionId(data.sessionId);
			setLoggedIn(data);
			setError(false);
		},
		onFailure: (data) => {
			setError(true);
		}
	});

	const userPool = new CognitoUserPool(poolData);

	const signIn = (username, password) => {
		const authenticationData = {
			Username: username,
			Password: password,
		};

		const authenticationDetails = new AuthenticationDetails(
			authenticationData
		);

		const userData = {
			Username: username,
			Pool: userPool,
		};

		const cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: function (result) {
				setUserEmail(result.getIdToken().payload.email);
				// setUserOrganization(
				// 	result.getIdToken().payload["custom:organizationcif"]
				// );
				// setNameOrganization(
				// 	result.getIdToken().payload["custom:organization"]
				// );
				setAccessToken(
					`Bearer ${result.getAccessToken().getJwtToken()}`
				);

				loginCall(result.getIdToken().getJwtToken());
			},
			onFailure: function (err) {
				showError('The email or password is incorrect')
				console.error("Error en el inicio de sesión", err);
			},
			newPasswordRequired: function (
				userAttributes,
				requiredAttributes
			) {
				navigate(`${ADD_PASSWORD}?code=Cambiar123!&user=${userAttributes.email}`);
			}
		});
	};

	useEffect(() => {
		if (auth?.user?.access_token && !hasLoggedIn) {
			const token = auth.user.access_token;
			if (!sessionStorage.getItem('processedToken')) {
				sessionStorage.setItem('processedToken', token);
				setAccessToken(`Bearer ${token}`);
				setHasLoggedIn(true);
				loginCall(token);
			}
		}
	}, [auth, hasLoggedIn, loginCall]);


	const onSubmit = (data) => {
		signIn(data.email, data.password);
	};


	useEffect(() => {
		if (isAuth && role) {
			navigate(role === 'DEVELOPER' || role === 'ADMIN' ? HOME : REQUESTS_MANAGEMENT); // Navega a /home si isAuth es true
		}
	}, [isAuth, navigate, role]);


	const handleAzureADLogin = async () => {
		try {
			await auth.signinRedirect();
		} catch (err) {
			console.error("Error al iniciar sesión con Azure AD", err);
			showError("Error al iniciar sesión con Azure AD");
		}
	};

	const defaultFormFieldProperties = {
		errors: errors,
		register: register,
		required: true,
		defaultValue: "",
	};

	const navigateToForgotPassowrd = () => {
		navigate(FORGOT_PASSWORD);
	};

	const navigateToRegister = () => {
		navigate(REGISTER);
	};

	return (
		<div className="login-form">
			<div className="login-form login-form__welcome">
				<h1 className="login-form__welcome__title">Welcome to <br />PagoNxt Payments <br />Developer Portal</h1>
			</div>
			<div className="login-form login-form__login">
				<div className="login-full-screen-layout__form__logo"></div>
				<Outlet />
				<div className="login-form__title">Log in</div>
				<div className="form-content">
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<div className="input-wrapper">
							<div className="input-wrapper__title">
								<p>Email</p>
							</div>
							<InputFormComponent
								{...defaultFormFieldProperties}
								inputType="text"
								placeholder={"Insert your email"}
								field="email"
								//pattern={namePattern}
								patternErrorFieldPrefix={
									"app.register.profile.field."
								}

								maxLength={40}
								minLength={2}
							/>
						</div>
						<div className="input-wrapper">
							<div className="input-wrapper__title">
								<p>Password</p>

								<div
									onClick={navigateToForgotPassowrd}
									className="input-wrapper__title__forgot"
								>
									Forgot password?
								</div>
							</div>
							<PasswordInput
								placeholder={"Insert your password"}
								defaultFormFieldProperties={
									defaultFormFieldProperties
								}
							/>
						</div>
						{
							error ?

								<div className="login-form__error">
									<span></span>
									The email or password is incorrect
								</div> : null
						}

						<button className="login-form__button" type="submit">{"Login"}</button>
						<button className="login-form__button" onClick={handleAzureADLogin}>{"Sign in with Azure AD"}</button>
					</form>

					<div className="form-content__create-account">
						Don't have an account? <br />
						<span onClick={navigateToRegister}>
							Create an account
						</span>
					</div>
				</div>
			</div>
		</div>

	);
};

export default LoginPage;
