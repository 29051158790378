import React, { useState } from "react";

import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import FormBecomeCustomer from "../../organisms/form-become-customer/FormBecomeCustomer";

import { HOME } from "../../utils/Routes";
import FormRequestSubscription from "../../organisms/form-request-subscription/FormRequestSubscription";

const FormRequestSubscriptionPage = () => {
  const [showBecomeCustomerModal, setShowBecomeCustomerModal] = useState(false);
  const handleOpenBecomeCustomerModal = () => setShowBecomeCustomerModal(true);

  return (
    <>
      <div className="request-subscription">
        <div className="request-subscription__heading">
          <div className="request-subscription__heading__back-button">
            <BackButtonComponent text="Back to APP list" url={HOME} />
          </div>
          <div className="request-subscription__heading__title">
            <h1>Request subscription</h1>
          </div>
          <div className="request-subscription__heading__description">
            <p>
              You can subscribe to a sandbox to test the API in practice, and
              when you're ready, change your subscription to the live
              environment and let your app conquer the world.
            </p>
          </div>
        </div>

        {/* FORMULARIO */}
        <FormRequestSubscription
          onOpenBecomeCustomerModal={handleOpenBecomeCustomerModal}
        />
      </div>

      <ModalComponent
        show={showBecomeCustomerModal}
        onClose={() => setShowBecomeCustomerModal(false)}
        size={"xl"}
      >
        <FormBecomeCustomer onClose={() => setShowBecomeCustomerModal(false)} />
      </ModalComponent>
    </>
  );
};

export default FormRequestSubscriptionPage;
