export const arrayToSelectOptions = (arr) => {
  return arr.map((item) => ({
    value: capitalizeEachWord(item),
    label: capitalizeEachWord(item),
  }));
};

export const capitalizeEachWord = (str) => {
  return str.split(" ").map(capitalize).join(" ");
};

export const capitalize = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const createOrganizationTypeMap = (typeOrganization) => {
  const map = {};
  typeOrganization.forEach((type) => {
    map[type.codOrganization] = type.description;
  });
  return map;
};

export const getTypeColor = (type) => {
  switch (type) {
    case "SUBSCRIBE":
      return "#D6E0FF"; // Morado
    case "SUPPORT":
      return "#DDE7EE"; // Gris
    case "DELETE":
      return "#F7C5C5"; // Rojo
    case "MODIFY":
      return "#FCE1C2"; // Amarillo
    case "NEW_CUSTOMER":
      return "#D9F4EB"; // Verde
    default:
      return "#fff"; // Color por defecto
  }
};
