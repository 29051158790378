import Section from "./Section";
import CredentialInput from "./CredentialInput";

import LinkComponent from "../../../atoms/link-component/LinkComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";
import {
  SANDBOXDOCUMENTATION,
  PREDOCUMENTATION,
  LIVEDOCUMENTATION,
} from "../../../utils/Routes";

import { SANDBOX } from "../../../utils/GlobalConsts";

const CredentialsSection = ({ isPending, currentEnv, filteredData }) => {
  return (
    <Section>
      <div className="request-modification-tab-content__sections__documentation">
        {currentEnv === "SANDBOX" && (
          <LinkComponent
            text={"Related documentation"}
            url={SANDBOXDOCUMENTATION}
            externalURL={true}
            icon={"icon-external"}
            className={"back-button__text"}
          />
        )}
        {currentEnv === "PRE" && (
          <LinkComponent
            text={"Related documentation"}
            url={PREDOCUMENTATION}
            externalURL={true}
            icon={"icon-external"}
            className={"back-button__text"}
          />
        )}
        {currentEnv === "LIVE" && (
          <LinkComponent
            text={"Related documentation"}
            url={LIVEDOCUMENTATION}
            externalURL={true}
            icon={"icon-external"}
            className={"back-button__text"}
          />
        )}
      </div>
      <Section.Title>Credentials</Section.Title>
      <Section.Description>
        Confidential information provided by PagoNxt, please do not share.
      </Section.Description>
      <CredentialInput
        title="Secret"
        value={
          filteredData?.clientAppId
            ? filteredData.clientAppId
            : "secret_provided_by_PagoNxt"
        }
      />
      <CredentialInput
        title="Client ID"
        value={
          filteredData?.secret
            ? filteredData.secret
            : "clientID_provided_by_PagoNxt"
        }
      />
      {currentEnv === SANDBOX && (
        <>
          <CredentialInput
            title="URL"
            value={
              filteredData?.url
                ? filteredData.url
                : "clientID_provided_by_PagoNxt"
            }
          />
          <CredentialInput
            title="Predefined Bearer token"
            value="7f697073-cddf-4b51-995f-aab611fe8ae9"
          />
        </>
      )}

      {
        // REQUEST IN PROGRESS
        isPending && (
          <div className="request-modification-tab-content__sections__section__pending-advice">
            <AlertComponent
              icon={"icon-clock"}
              text={"Request in progress to validate"}
              bgColor={"bg--brand-warning-100"}
            />
          </div>
        )
      }
    </Section>
  );
};

export default CredentialsSection;
