import FormRequestSubscriptionInput from "./FormRequestSubscriptionInput";
import AlertComponent from "../../../atoms/alert-component/alertComponent";
import { SANDBOX, PENDING } from "../../../utils/GlobalConsts";

const SelectApiSection = ({ formData, handleChange }) => {
  return (
    <FormRequestSubscriptionInput
      number={3}
      title="Select API to subscribe"
      preContentSlot={
        <>
          {formData.environment === SANDBOX && (
            <div className="request-subscription__form__alert">
              <AlertComponent
                text={
                  "In SandBox all of our APIs are available for you to try. Use the ones you want to test"
                }
                icon={"icon-info"}
                bgColor={"bg--brand-neutral-100"}
              />
            </div>
          )}
        </>
      }
    >
      <FormRequestSubscriptionInput.Content>
        <div className="input-content__checkboxes">
          <div className="input-content__checkboxes__checkbox">
            <input
              type="checkbox"
              name="apiIso"
              id="apiIso"
              checked={formData.subscribeAPI.apiIso === PENDING}
              value={formData.subscribeAPI.apiIso}
              onChange={handleChange}
              disabled={formData.environment === SANDBOX}
            />
            <label htmlFor="apiIso">
              <div className="input-content__checkboxes__checkbox__content">
                <div className="input-content__checkboxes__checkbox__box"></div>
                <div className="input-content__checkboxes__checkbox__text">
                  <div className="input-content__checkboxes__checkbox__text__title">
                    <span>API ISO20022</span>
                  </div>
                  <div className="input-content__checkboxes__checkbox__text__description">
                    <span>Brief description of the API utility</span>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </FormRequestSubscriptionInput.Content>
    </FormRequestSubscriptionInput>
  );
};

export default SelectApiSection;
