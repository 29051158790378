import FullScreenLayout from "./components/layouts/FullScreenLayout";
import PrivateFullScreenLayout from "./components/layouts/AdminFullScreenLayout";
import LoginFullScreenLayout from "./components/layouts/LoginFullScreenLayout";
import LegalFullScreenLayout from "./components/layouts/LegalScreenLayout";
import PrivateRoute from "./components/utils/PrivateRoute";

import { GlobalVarProvider } from "./components/utils/GlobalContext";
import { OrganizationProvider } from "./components/context/OrganizationContext";
import { LoginOrganizationProvider } from "./components/context/LoginOrganizationContext";
import { Route, Routes } from "react-router";
import { Navigate, Outlet } from "react-router";
import { useGlobalVar } from "./components/utils/GlobalContext";

import NotificationsContainer from "./components/utils/NotificationsContainer";
import HomePage from "./components/pages/home/HomePage";
import LoginBackendPage from "./components/pages/login/LoginBackendPage";
import LoginPage from "./components/pages/login/LoginPage";
import LogoutPage from "./components/pages/logout/LogoutPage";
import NotFoundPage from "./components/error/NotFoundPage";
import SignUpPage from "./components/pages/signUp/SignUpPage";

import FormRequestSubscription from "./components/pages/form-request-subscription/FormRequestSubscription";
import RequestModification from "./components/pages/request-modification/RequestModification";
import UserSettings from "./components/pages/user-settings/UserSettings";
import ManageTeamUsers from "./components/pages/manage-users/ManageTeamUsers";
import EditUserForm from "./components/pages/edit-user-form/EditUserForm";
import ManageOrganizations from "./components/pages/admin-area-organizations/admin-area-manage-organizations/ManageOrganizations";
import RequestDetails from "./components/pages/admin-area-requests/admin-area-request-detail/RequestDetails";
import CreateOrganization from "./components/pages/admin-area-organizations/admin-area-create-organization/CreateOrganization";
import EditOrganization from "./components/pages/admin-area-organizations/admin-area-edit-organization/EditOrganization";
import DetailsOrganization from "./components/pages/admin-area-organizations/admin-area-organization-details/DetailsOrganization";
import RequestsManagement from "./components/pages/admin-area-requests/admin-area-requests-management/RequestsManagement";
import CreateNewAdministrator from "./components/pages/admin-area-settings/admin-area-new-administrator/CreateNewAdministrator";
import PrivacyNotice from "./components/pages/privacy-notice/PrivacyNotice";
import LegalNotice from "./components/pages/legal-notice/LegalNotice";
import CookiePolicy from "./components/pages/cookie-policy/CookiePolicy";
import CodeConduct from "./components/pages/code-conduct/CodeConduct";

import "./css/app.scss";
import {
	HOME,
	FORM_REQUEST_SUBSCRIPTION,
	REQUEST_MODIFICATION,
	REQUEST_MODIFICATION_ADMIN,
	INDEX,
	SIGNUP,
	SIGNUP_REQUEST,
	REGISTER,
	USER_SETTINGS,
	MANAGE_USERS,
	EDIT_USERS_TEAM,
	REQUEST_DETAIL,
	REQUESTS_MANAGEMENT,
	MANAGE_ORGANIZATIONS,
	CREATE_ORGANIZATION,
	DETAILS_ORGANIZATION,
	EDIT_ORGANIZATION,
	USERS,
	EDIT_USERS,
	AUDIT,
	AUDIT_DETAILS,
	SETTINGS,
	CREATE_ADMINISTRATOR,
	ADMIN_USER_SETTINGS,
	ADD_USERS,
	ADD_PASSWORD,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	PRIVACY_NOTICE,
	LEGAL_NOTICE,
	COOKIE_POLICY,
	CODE_CONDUCT
} from "./components/utils/Routes";
import SignupRequestPage from "./components/pages/signUp/SignupRequestPage";
import RequestProvider from "./components/context/AuditContext";
import ManageUsers from "./components/pages/admin-area-users/admin-area-manage-users/ManageUsers";
import EditUsers from "./components/pages/admin-area-users/admin-area-edit-users/EditUsers";
import AuditManagement from "./components/pages/admin-area-audit/admin-area-audit-management/AuditManagement";
import AuditDetails from "./components/pages/admin-area-audit/admin-area-audit-detail/AuditDetails";
import SettingsManagement from "./components/pages/admin-area-settings/admin-area-settings-management/SettingsManagement";
import UserSettingsAdmin from "./components/pages/admin-area-settings/admin-area-user-settings/UserSettingsAdmin";
import AddUserForm from "./components/pages/form-add-user/AddUserForm";
import RegisterPage from "./components/pages/register-page/RegisterPage";
import AddPasswordPage from "./components/pages/add-password-page/AddPasswordPage";
import ForgotPasswordPage from "./components/pages/forgot-password-page/ForgotPasswordPage";
import ResetPassword from "./components/pages/reset-password/ResetPassword";

function App() {
	function DeveloperOnlyRoute() {
		const { isAuth, role } = useGlobalVar();

		
		// Si no está autenticado, redirigir al login
		if (!isAuth) {
			return <Navigate to="/" />;
		}

		// // Si el rol no es DEVELOPER, redirigir a una página de error o a otra ruta
		if (role !== "DEVELOPER" && role !== "ADMIN") {
			return <Navigate to="/not-found" />;
		}

		// Si el rol es DEVELOPER, permitir el acceso a la ruta
		return <Outlet />;
	}

	function AdminOnlyRoute() {
		const { isAuth, role } = useGlobalVar();

		// Si no está autenticado, redirigir al login
		if (!isAuth) {
			return <Navigate to="/" />;
		}

		// // Si el rol no es ADMIN, redirigir a una página de error o a otra ruta
		if (role !== "MASTER") {
			return <Navigate to="/not-found" />;
		}

		// Si el rol es ADMIN, permitir el acceso a la ruta
		return <Outlet />;
	}

	return (
		<div>
			<GlobalVarProvider>
				<LoginOrganizationProvider>
					<OrganizationProvider>
						<RequestProvider>
							<NotificationsContainer />
							<Routes>
								<Route element={<LoginFullScreenLayout />}>
									{/* <Route
										path={INDEX}
										element={<IndexPage />}
									/> */}
									<Route
										path={INDEX}
										element={<LoginPage />}
									/>
									<Route
										path={SIGNUP_REQUEST}
										element={<SignupRequestPage />}
									/>
									<Route
										path={REGISTER}
										element={<RegisterPage />}
									/>
									<Route
										path={ADD_PASSWORD}
										element={<AddPasswordPage />}
									/>
									<Route
										path={FORGOT_PASSWORD}
										element={<ForgotPasswordPage />}
									/>
									<Route
										path={RESET_PASSWORD}
										element={<ResetPassword />}
									/>
								</Route>

								<Route element={<LegalFullScreenLayout />}>
									<Route
										path={PRIVACY_NOTICE}
										element={<PrivacyNotice />}
									/>
									<Route
										path={LEGAL_NOTICE}
										element={<LegalNotice />}
									/>
									<Route
										path={COOKIE_POLICY}
										element={<CookiePolicy />}
									/>
									<Route
										path={CODE_CONDUCT}
										element={<CodeConduct />}
									/>
								</Route>

								<Route element={<PrivateRoute />}>
									<Route element={<DeveloperOnlyRoute />}>
										<Route element={<FullScreenLayout />}>
											<Route
												path={SIGNUP}
												element={<SignUpPage />}
											/>
											<Route
												path="/loginBack"
												element={<LoginBackendPage />}
											/>
											<Route>
												<Route
													path={HOME}
													element={<HomePage />}
												/>
												<Route
													path={
														FORM_REQUEST_SUBSCRIPTION
													}
													element={
														<FormRequestSubscription />
													}
												/>
												<Route
													path={REQUEST_MODIFICATION}
													element={
														<RequestModification />
													}
												/>
												<Route
													path={USER_SETTINGS}
													element={<UserSettings />}
												/>
												<Route
													path={MANAGE_USERS}
													element={
														<ManageTeamUsers />
													}
												/>

												<Route
													path={ADD_USERS}
													element={<AddUserForm />}
												/>

												<Route
													path={EDIT_USERS_TEAM}
													element={<EditUserForm />}
												/>

												<Route
													path="/logout"
													element={<LogoutPage />}
												/>
											</Route>
										</Route>
									</Route>

									<Route element={<AdminOnlyRoute />}>
										<Route
											element={
												<PrivateFullScreenLayout />
											}
										>
											<Route
												path={REQUESTS_MANAGEMENT}
												element={<RequestsManagement />}
											/>
											<Route
												path={REQUEST_DETAIL}
												element={<RequestDetails />}
											/>

											<Route
												path={MANAGE_ORGANIZATIONS}
												element={
													<ManageOrganizations />
												}
											/>
											<Route
												path={DETAILS_ORGANIZATION}
												element={
													<DetailsOrganization />
												}
											/>
											<Route
												path={REQUEST_MODIFICATION_ADMIN}
												element={
													<RequestModification isClient />
												}
											/>

											<Route
												path={CREATE_ORGANIZATION}
												element={<CreateOrganization />}
											/>
											<Route
												path={EDIT_ORGANIZATION}
												element={<EditOrganization />}
											/>
											<Route
												path={USERS}
												element={<ManageUsers mainPage={true}/>}
											/>
											<Route
												path={EDIT_USERS}
												element={<EditUsers />}
											/>
											<Route
												path={AUDIT}
												element={<AuditManagement />}
											/>
											<Route
												path={AUDIT_DETAILS}
												element={<AuditDetails />}
											/>
											<Route
												path={SETTINGS}
												element={<SettingsManagement />}
											/>
											<Route
												path={CREATE_ADMINISTRATOR}
												element={
													<CreateNewAdministrator />
												}
											/>
											<Route
												path={ADMIN_USER_SETTINGS}
												element={<UserSettingsAdmin />}
											/>
										</Route>
									</Route>
								</Route>

								<Route
									path="/not-found"
									element={<NotFoundPage />}
								/>
							</Routes>
						</RequestProvider>
					</OrganizationProvider>
				</LoginOrganizationProvider>
			</GlobalVarProvider>
		</div>
	);
}

export default App;
