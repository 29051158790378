const CredentialInput = ({ title, value }) => {
  return (
    <div className="request-modification-tab-content__sections__section__credential input-content">
      <span className="request-modification-tab-content__sections__section__credential__title">
        {title}
      </span>
      <span className="request-modification-tab-content__sections__section__credential__data">
        {value}
      </span>
    </div>
  );
};

export default CredentialInput;
