import React, { useEffect, useState, useCallback } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";
import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";
import SearchComponent from "../../../molecules/search-component/SearchComponent";
import SelectFilterComponent from "../../../molecules/select-filter-component/SelectFilterComponent";
import DateFilterComponent from "../../../molecules/date-filter-component/DateFilterComponent";
import { getRequestsList } from "../../../../services/ApiRequest";
import { useNavigate } from "react-router-dom";
import { REQUEST_DETAIL } from "../../../utils/Routes";
import useTableData from "../../../../hooks/admin-area/useTableData";
import { getTypeColor } from "../../../utils/Utils";

const columnWidths = ["15%", "25%", "25%", "25%", "10%"];
const requesUserHeader = ["Type", "Organization", "Username", "Email", "Date"];
const headerToFieldMap = {
  Type: "type",
  Organization: "organization",
  Username: "userName",
  Email: "email",
  Date: "date",
};

const RequestsManagement = () => {
  const navigate = useNavigate();
  const { accessToken, setLoggedOut } = useGlobalVar();

  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState([]);

  /* Table data */
  const {
    currentPage,
    itemsPerPage,
    searchTerm,
    startDate,
    endDate,
    sortColumn,
    sortOrder,
    filterType,
    handleSearch,
    handleDateRangeChange,
    handleFilterTypeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handleSort,
    getSortedData,
  } = useTableData();

  const logout = useCallback(() => {
    setLoggedOut();
    navigate("/logout");
  }, [navigate, setLoggedOut]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestListData = await getRequestsList(accessToken);

        // Asegurarse de que requestListData sea siempre un array
        const validData = Array.isArray(requestListData) ? requestListData : [];

        [...validData].reverse();

        setLoading(false);
        setRequestData(validData);
      } catch (error) {
        if (error.response.status === 403) {
          logout();
        }
        console.error("Error fetching data:", error);
        setRequestData([]); // En caso de error, establece un array vacío
      }
    };

    fetchData();
  }, [accessToken, logout]);

  // Función para manejar la búsqueda, filtro y ordenamiento
  const getFilteredAndSortedData = () => {
    let data = Array.isArray(requestData) ? [...requestData] : [];

    // Aplicar búsqueda
    data = data.filter((item) => {
      const itemDate = new Date(item.date);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      const termToFilter = searchTerm.trim().toLowerCase();
      return (
        (!filterType || item.type === filterType) &&
        (item.type.toLowerCase().includes(termToFilter) ||
          item.userName.toLowerCase().includes(termToFilter) ||
          item.email.toLowerCase().includes(termToFilter) ||
          item.organization.toLowerCase().includes(termToFilter)) &&
        (!start || itemDate >= start) &&
        (!end || itemDate <= end)
      );
    });

    const sortedAndFilteredData = getSortedData({ data, headerToFieldMap });

    return sortedAndFilteredData;
  };

  const handleRowClick = (rowIndex) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + rowIndex;
    const rowData = getFilteredAndSortedData()[globalIndex];

    navigate(REQUEST_DETAIL, { state: { rowData } });
  };

  const requestTypes = [...new Set(requestData.map((request) => request.type))];

  const filteredAndSortedData = getFilteredAndSortedData();

  const currentData = filteredAndSortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderTableData = Array.isArray(currentData)
    ? currentData.map((item) => ({
        ...item,
        date_created: item.date.split("T")[0], // Tomar solo la fecha antes de la T
        type: (
          <span
            style={{
              display: "inline-block",
              padding: "4px 8px",
              borderRadius: "16px",
              backgroundColor: getTypeColor(item.type),
              color: "black",
            }}
          >
            {item.type}
          </span>
        ),
      }))
    : [];

  if (loading) return <div>Loading...</div>;

  return (
    <div className="request-subscription request-management">
      <div className="request-subscription__heading mg mg--t-4">
        <h1>Request management</h1>
      </div>
      <div className="request-subscription__form">
        <div className="request-management__actions">
          <SearchComponent onSearch={handleSearch} />
          <div className="request-management__filters">
            <SelectFilterComponent
              placeholder={"Type of request"}
              options={requestTypes}
              onChange={handleFilterTypeChange}
              className={"input--extrasmall"}
            />
            <DateFilterComponent onDateRangeChange={handleDateRangeChange} />
          </div>
        </div>

        <div className="mg mg--t-2">
          <TableComponent
            headers={requesUserHeader}
            headerToFieldMap={headerToFieldMap}
            data={renderTableData}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onRowClick={handleRowClick}
            combineName={true}
            showBtn={false}
            columnWidths={columnWidths}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(filteredAndSortedData.length / itemsPerPage)}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestsManagement;
