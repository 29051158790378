import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";
import SearchComponent from "../../../molecules/search-component/SearchComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import DateFilterComponent from "../../../molecules/date-filter-component/DateFilterComponent";
import LinkComponent from "../../../atoms/link-component/LinkComponent";

import { createOrganizationTypeMap } from "../../../utils/Utils";
import { useGlobalVar } from "../../../utils/GlobalContext";

import { success as showSuccess } from "../../../utils/NotificationsContainer";
import useTableData from "../../../../hooks/admin-area/useTableData";

import {
  getOrganizationsList,
  getOrganizationsTypes,
  getOrganizationsExcel,
} from "../../../../services/ApiOrganization";
import {
  CREATE_ORGANIZATION,
  DETAILS_ORGANIZATION,
} from "../../../utils/Routes";

import { useOrganization } from "../../../context/OrganizationContext";

const columnWidths = ["25%", "30%", "30%", "15%"];
const headerOrganizationData = ["Organization", "Type", "Email", "Contract"];
const headerToFieldMap = {
  Organization: "nameOrganization",
  Type: "codOrganization",
  Email: "email",
  Contract: "contract",
};

const ManageOrganizations = () => {
  const navigate = useNavigate();
  const { setOrganizationId } = useOrganization();
  const { accessToken, setLoggedOut } = useGlobalVar();

  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [organizationTypeMap, setOrganizationTypeMap] = useState({});

  /* Table data */
  const {
    currentPage,
    itemsPerPage,
    searchTerm,
    sortColumn,
    sortOrder,
    handleSearch,
    handleDateRangeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handleSort,
    getSortedData,
  } = useTableData({ data: data, headerToFieldMap });

  const logout = useCallback(() => {
    setLoggedOut();
    navigate("/logout");
  }, [navigate, setLoggedOut]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationListData = await getOrganizationsList(accessToken);
        const typeOrganizationData = await getOrganizationsTypes(accessToken);
        setData(organizationListData);
        setOrganizationTypeMap(
          createOrganizationTypeMap(typeOrganizationData, accessToken)
        );
      } catch (error) {
        if (error.response.status === 403) {
          logout();
        }
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken, logout]);

  useEffect(() => {
    // Filtra los datos según el término de búsqueda
    const termToFilter = searchTerm.trim().toLowerCase();
    const filtered = data.filter((item) =>
      item.nameOrganization.toLowerCase().includes(termToFilter)
    );

    // Ordena los datos filtrados
    const sorted = getSortedData({ data: filtered, headerToFieldMap });
    setSortedData(sorted);
  }, [data, searchTerm, sortColumn, sortOrder, getSortedData]);

  const handleButtonClick = (rowIndex) => {
    alert(`Button clicked in row ${rowIndex}`);
  };

  const handleClick = () => {
    navigate(CREATE_ORGANIZATION);
  };

  const handleRowClick = (rowIndex) => {
    const organization = sortedData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    )[rowIndex];

    localStorage.setItem("currentOrganizationId", organization.cif);
    localStorage.setItem(
      "currentOrganizationName",
      organization.nameOrganization
    );
    localStorage.setItem(
      "currentOrganizationCod",
      organization.codOrganization
    );

    setOrganizationId(organization.cif);
    navigate(DETAILS_ORGANIZATION);
  };

  const downloadExcel = async () => {
    try {
      const response = await getOrganizationsExcel(accessToken);
      // Obtener el nombre y el archivo codificado en base64
      const { nome, arquivo } = response;

      // Decodificar la cadena base64
      const byteCharacters = atob(arquivo);

      // Convertir la cadena base64 en un ArrayBuffer
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Crear un enlace de descarga
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nome); // Usa el nombre que viene en la respuesta

      // Simular clic automáticamente para descargar el archivo
      link.style.display = "none"; // Ocultar el enlace del DOM
      document.body.appendChild(link);
      link.click();

      // Limpiar el enlace y liberar la URL del blob
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error.response.status === 403) {
        logout();
      }
      console.error("Error fetching data:", error);
    }
  };

  const renderTableData = sortedData
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map((item) => ({
      ...item,
      contract: (
        <span>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: item.contract === "true" ? "#47D7AC" : "#9FA6AD",
              marginRight: "8px",
            }}
          ></span>
          {item.contract === "false" || item.contract === "Inactive"
            ? "Inactive"
            : "Active"}
        </span>
      ),
    }));

  useEffect(() => {
    const reloadOrgCreated = localStorage.getItem("reloadOrgCreated");

    if (reloadOrgCreated) {
      showSuccess(`New organization successfully added`);
      localStorage.removeItem("reloadOrgCreated");
    }
  }, []);

  return (
    <div className="request-subscription">
      <div className="request-subscription__heading mg mg--t-4">
        <h1>Organizations</h1>
      </div>
      <div className="request-subscription__form">
        <div className="request-management__actions">
          <SearchComponent onSearch={handleSearch} />
          <div className="request-management__filters">
            <LinkComponent
              text={"Export list"}
              icon={"icon-download"}
              borderColor={"brand-neutral-300"}
              onClick={downloadExcel}
            />
            <DateFilterComponent onDateRangeChange={handleDateRangeChange} />
          </div>
        </div>

        <div className="mg mg--t-2">
          <TableComponent
            headers={headerOrganizationData}
            data={renderTableData}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onButtonClick={handleButtonClick}
            onRowClick={handleRowClick}
            showBtn={false}
            headerToFieldMap={headerToFieldMap}
            organizationTypeMap={organizationTypeMap}
            columnWidths={columnWidths}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(sortedData.length / itemsPerPage)}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>

        <ButtonComponent text={"Add new organization"} onClick={handleClick} />
      </div>
    </div>
  );
};

export default ManageOrganizations;
