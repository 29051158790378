import { useState } from "react";

const DEFAULT_ITEMS_PER_PAGE = 10;
const DEFAULT_SORT_ORDER = "asc";

const useTableData = () => {
  const [filterType, setFilterType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const handleSort = (header) => {
    const newSortOrder =
      sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(header);
    setSortOrder(newSortOrder);
  };

  const getSortedData = ({ data, headerToFieldMap }) => {
    if (!data || data?.length <= 0) return [];

    const isAscending = sortOrder === "asc";

    let sortedData = [...data];

    if (sortColumn) {
      sortedData.sort((a, b) => {
        const field = headerToFieldMap[sortColumn] || sortColumn;
        const valueA = a[field];
        const valueB = b[field];

        /* Numeric fields case */
        if (typeof valueA === "number" && typeof valueB === "number") {
          return isAscending ? valueA - valueB : valueB - valueA;
        }

        /* String fields case */
        const strA = String(valueA).toLowerCase();
        const strB = String(valueB).toLowerCase();

        console.log(field, headerToFieldMap, sortColumn);

        if (strA < strB) return isAscending ? -1 : 1;
        if (strA > strB) return isAscending ? 1 : -1;
        return 0;
      });
    }

    return sortedData;
  };

  return {
    currentPage,
    itemsPerPage,
    searchTerm,
    startDate,
    endDate,
    sortColumn,
    sortOrder,
    filterType,
    handlePageChange,
    handleItemsPerPageChange,
    handleSearch,
    handleDateRangeChange,
    handleFilterTypeChange,
    handleSort,
    getSortedData,
  };
};

export default useTableData;
