import React, { useEffect, useState } from "react";
import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";
import SearchComponent from "../../../molecules/search-component/SearchComponent";
import DateFilterComponent from "../../../molecules/date-filter-component/DateFilterComponent";
import { getAuditList } from "../../../../services/ApiAudit";
import { useNavigate } from "react-router-dom";
import { AUDIT_DETAILS } from "../../../utils/Routes";
import { useGlobalVar } from "../../../utils/GlobalContext";
import useTableData from "../../../../hooks/admin-area/useTableData";
import { getTypeColor } from "../../../utils/Utils";

const columnWidths = ["15%", "15%", "20%", "15%", "15%", "15%", "20%"];
const requesUserHeader = [
  "Type",
  "Status",
  "Organization",
  "Name",
  "Lastname",
  "Email",
  "Date",
];
const headerToFieldMap = {
  Type: "typeAudit",
  Status: "status",
  Organization: "organizations",
  Name: "firstname",
  Lastname: "lastname",
  Email: "email",
  Date: "date_created",
};

const AuditManagement = () => {
  const { accessToken } = useGlobalVar();
  const navigate = useNavigate();

  const [auditData, setAuditData] = useState([]);

  const {
    currentPage,
    itemsPerPage,
    searchTerm,
    startDate,
    endDate,
    sortColumn,
    sortOrder,
    filterType,
    handleSearch,
    handleDateRangeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handleSort,
    getSortedData,
  } = useTableData();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestListData = await getAuditList(accessToken);

        // const reverseData = requestListData.reverse();
        const reverseData = requestListData;

        setAuditData(reverseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken]);

  const getFilteredAndSortedData = () => {
    let data = Array.isArray(auditData) ? [...auditData] : [];

    data = auditData.filter((item) => {
      const itemDate = new Date(item.date);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      const termToFilter = searchTerm.trim().toLowerCase();
      return (
        (!filterType || item.typeAudit === filterType) &&
        (item.typeAudit?.toLowerCase().includes(termToFilter) ||
          item.firstname?.toLowerCase().includes(termToFilter) ||
          item.email?.toLowerCase().includes(termToFilter) ||
          item.organizations?.toLowerCase().includes(termToFilter)) &&
        (!start || itemDate >= start) &&
        (!end || itemDate <= end)
      );
    });

    const sortedAndFilteredData = getSortedData({ data, headerToFieldMap });

    return sortedAndFilteredData;
  };

  const filteredAndSortedData = getFilteredAndSortedData();

  const currentData = filteredAndSortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Renderizado de las filas con los estilos aplicados
  const renderTableData = currentData.map((item) => ({
    ...item,
    date_created: item.date_created.split("T")[0], // Tomar solo la fecha antes de la T
    status: (
      <span>
        <span
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: item.status === "Done" ? "#47D7AC" : "#C41C1C",
            marginRight: "8px",
          }}
        ></span>
        {item.status}
      </span>
    ),
    typeAudit: (
      <span
        style={{
          display: "inline-block",
          padding: "4px 8px",
          borderRadius: "16px",
          backgroundColor: getTypeColor(item.typeAudit),
          color: "black",
        }}
      >
        {item.typeAudit}
      </span>
    ),
  }));

  const handleRowClick = (rowIndex) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + rowIndex;
    const rowData = filteredAndSortedData[globalIndex];
    navigate(AUDIT_DETAILS, { state: { rowData } });
  };

  return (
    <div className="request-subscription request-management admin-management">
      <div className="request-subscription__heading mg mg--t-4">
        <h1>Audit Log</h1>
      </div>
      <div className="request-subscription__form">
        <div className="request-management__actions">
          <SearchComponent onSearch={handleSearch} />
          <div className="request-management__filters">
            <DateFilterComponent onDateRangeChange={handleDateRangeChange} />
          </div>
        </div>

        <div className="mg mg--t-2">
          <TableComponent
            headers={requesUserHeader}
            headerToFieldMap={headerToFieldMap}
            data={renderTableData} // Pasamos los datos transformados aquí
            onSort={handleSort}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onRowClick={handleRowClick}
            combineName={true}
            showBtn={false}
            columnWidths={columnWidths}
          />
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(filteredAndSortedData.length / itemsPerPage)}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditManagement;
