import React, {
	createContext,
	useEffect,
	useContext,
	useState,
	useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js"; // Importar CryptoJS

import { HOME, REQUESTS_MANAGEMENT } from "./Routes";
import { getOrganizationsDetails } from "../../services/ApiOrganization";
import { getBecomeCustomerSended, getUserData } from "../../services/ApiUsers";

// Clave de encriptación (esto debería almacenarse en un entorno seguro)
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
// Función para encriptar datos
const encryptData = (data) => {
	return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

// Función para desencriptar datos
const decryptData = (cipherText) => {
	try {
		const bytes = CryptoJS.AES.decrypt(cipherText, encryptionKey);
		const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedData);
	} catch (e) {
		console.error("Error desencriptando datos:", e);
		return null;
	}
};

// 1. Crear el contexto
const GlobalVarContext = createContext();

// 2. Crear un componente proveedor que establecerá y gestionará las variables globales
export const GlobalVarProvider = ({ children }) => {
	const navigate = useNavigate();

	// Recuperar datos de sessionStorage desencriptando
	const [expirationTime, setExpirationTime] = useState(() => {
		const storedExpirationTime = sessionStorage.getItem("expirationTime");
		return storedExpirationTime ? decryptData(storedExpirationTime) : false;
	});

	const [role, setRole] = useState(() => {
		const storedRole = sessionStorage.getItem("role");
		return storedRole ? decryptData(storedRole) : false;
	});

	const [isAuth, setIsAuth] = useState(() => {
		const storedIsAuth = sessionStorage.getItem("isAuth");
		return storedIsAuth ? decryptData(storedIsAuth) : false;
	});

	const [sessionId, setSessionId] = useState(() => {
		const storedSessionId = sessionStorage.getItem("sessionId");
		return storedSessionId ? decryptData(storedSessionId) : false;
	});

	const [accessToken, setAccessToken] = useState(() => {
		const storedAccessToken = sessionStorage.getItem("accessToken");
		return storedAccessToken ? decryptData(storedAccessToken) : false;
	});

	const [userEmail, setUserEmail] = useState(() => {
		const storedUserEmail = sessionStorage.getItem("userEmail");
		return storedUserEmail ? decryptData(storedUserEmail) : false;
	});

	const [userOrganization, setUserOrganization] = useState(() => {
		const storedUserOrganization =
			sessionStorage.getItem("organizationcif");
		return storedUserOrganization
			? decryptData(storedUserOrganization)
			: false;
	});

	const [nameOrganization, setNameOrganization] = useState(() => {
		const storedNameOrganization =
			sessionStorage.getItem("nameOrganization");
		return storedNameOrganization
			? decryptData(storedNameOrganization)
			: false;
	});

	const [userData, setUserData] = useState(() => {
		const storedUserData = sessionStorage.getItem("userData");
		return storedUserData ? decryptData(storedUserData) : false;
	});

	const [becomeCustomerSended, setBecomeCustomerSended] = useState(() => {
		const storedBecomeCustomerSended= sessionStorage.getItem("becomeCustomerSended");
		return storedBecomeCustomerSended ? decryptData(storedBecomeCustomerSended) : false;
	});

	const fetchOrganizationDetails = async () => {
		try {
			const data = await getOrganizationsDetails(
				userOrganization,
				accessToken
			);

			// setNameOrganization(data?.organization?.name);
		} catch (error) {
			console.error("Error fetching organization data:", error);
		}
	};

	const fetchBecomeCustomerSended = async () => {

		try {
			const data = await getBecomeCustomerSended(
				userData?.email,
				accessToken
			);
			sessionStorage.setItem("becomeCustomerSended", encryptData(data.exists));

			setBecomeCustomerSended(data.exists);
		} catch (error) {
			console.error("Error fetching organization data:", error);
		}
	};

	const fetchUserData = async () => {

		try {
			const data = await getUserData(
				userData?.id,
				accessToken
			);

			// console.log('estos son los datos')
			// console.log(data);

			sessionStorage.setItem("userData", encryptData(data));
			
		} catch (error) {
			console.error("Error fetching organization data:", error);
		}
	};

	// Guardar datos en sessionStorage encriptados
	useEffect(() => {
		sessionStorage.setItem("role", encryptData(role));
		sessionStorage.setItem("isAuth", encryptData(isAuth));
		sessionStorage.setItem("sessionId", encryptData(sessionId));
		sessionStorage.setItem("accessToken", encryptData(accessToken));
		sessionStorage.setItem("userEmail", encryptData(userEmail));
		sessionStorage.setItem(
			"organizationcif",
			encryptData(userOrganization)
		);
		sessionStorage.setItem(
			"nameOrganization",
			encryptData(nameOrganization)
		);
		sessionStorage.setItem("expirationTime", encryptData(expirationTime));
		sessionStorage.setItem("userData", encryptData(userData));

		if(userData)
			fetchBecomeCustomerSended();

		// console.log(accessToken);
		// console.log("User isAuth " + isAuth);
		// console.log("User role " + role);
		// console.log("User organizationcif " + userOrganization);
		// console.log("User Data " + JSON.stringify(userData));

		// fetchUserData();
	}, [
		isAuth,
		sessionId,
		role,
		accessToken,
		userEmail,
		userOrganization,
		nameOrganization,
		expirationTime,
		userData,
		fetchBecomeCustomerSended
	]);

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return; // No ejecutar en el primer renderizado
		}

		if (isAuth && role) {
			navigate(
				role === "DEVELOPER" || role === "ADMIN"
					? HOME
					: REQUESTS_MANAGEMENT
			);
		}
	}, [isAuth, role]);

	

	const setLoggedIn = (data) => {
		setIsAuth(true);
		setUserData(data.userInfo);
		setRole(data.userInfo.roles[0].name);
		setUserOrganization(data.userInfo.cif !== '' ? data.userInfo.cif : data.userInfo.organizationCif);
		setNameOrganization(data.userInfo.organizationName)
	};

	const setLoggedOut = () => {
		setIsAuth(false);
		setRole(false);
		setSessionId(false);
		setAccessToken(false);
		setUserEmail(false);
		setUserOrganization(false);
		setNameOrganization(false);
		setExpirationTime(false);
		setUserData(false);
	};

	return (
		<GlobalVarContext.Provider
			value={{
				isAuth,
				setLoggedIn,
				setLoggedOut,
				sessionId,
				setSessionId,
				role,
				setRole,
				accessToken,
				setAccessToken,
				userEmail,
				setUserEmail,
				userOrganization,
				setUserOrganization,
				nameOrganization,
				fetchOrganizationDetails,
				setExpirationTime,
				userData,
				fetchBecomeCustomerSended,
				becomeCustomerSended,
				fetchUserData
			}}
		>
			{children}
		</GlobalVarContext.Provider>
	);
};

// 3. Crear un gancho personalizado para acceder a las variables globales
export const useGlobalVar = () => {
	const context = useContext(GlobalVarContext);
	if (!context) {
		throw new Error(
			"useGlobalVar debe usarse dentro de un GlobalVarProvider"
		);
	}
	return context;
};
