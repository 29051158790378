import FormRequestSubscriptionInput from "./FormRequestSubscriptionInput";
import EnvironmentInputRadio from "./EnvironmentInputRadioComponent";

import LinkComponent from "../../../atoms/link-component/LinkComponent";
import { SANDBOX, PRE, LIVE } from "../../../utils/GlobalConsts";

const RegisterAppSection = ({
  formData,
  userData,
  handleChange,
  becomeCustomerSended,
  onOpenBecomeCustomerModal,
  showPendingError,
}) => {
  return (
    <FormRequestSubscriptionInput number={2} title="Select environment">
      <FormRequestSubscriptionInput.Content>
        <div className="input-content__radios">
          {/* INPUT DE ENTORNO SANDBOX PRESELECCIONADO */}
          <EnvironmentInputRadio
            value={SANDBOX}
            name="environment"
            id={SANDBOX}
            htmlFor={SANDBOX}
            checked={formData.environment === "SANDBOX"}
            onChange={handleChange}
            title={SANDBOX}
            description="Use this environment to know our platform"
          >
            {!userData?.client || formData.environment === SANDBOX ? (
              <LinkComponent
                text={"Selected"}
                icon={"icon-check"}
                bgColor={"tertiary-100"}
                filled
              />
            ) : (
              <LinkComponent
                text={"Avaliable"}
                bgColor={"tertiary-100"}
                color={"tertiary-500"}
                borderColor={"tertiary-500"}
              />
            )}
          </EnvironmentInputRadio>

          {/* HABILITAMOS SELECCIÓN DE INPUT SI EL USUARIO ES CLIENTE */}
          <EnvironmentInputRadio
            type="radio"
            value={PRE}
            name="environment"
            id={userData?.client ? "PRE" : SANDBOX}
            checked={formData.environment === PRE}
            onChange={handleChange}
            htmlFor={PRE}
            title={PRE}
            description="Use all the features in your test environment"
          >
            {!userData?.client ? (
              <>
                {becomeCustomerSended ? (
                  <div onClick={() => showPendingError()}>
                    <LinkComponent
                      text={"Upgrade pending"}
                      icon={"icon-pending"}
                      bgColor={"bg-pending"}
                    />
                  </div>
                ) : (
                  <div onClick={onOpenBecomeCustomerModal}>
                    <LinkComponent
                      text={"Upgrade your plan"}
                      icon={"icon-spark"}
                      filled
                    />
                  </div>
                )}
              </>
            ) : userData?.client && formData.environment === "PRE" ? (
              <LinkComponent
                text={"Selected"}
                icon={"icon-check"}
                bgColor={"tertiary-100"}
                filled
              />
            ) : (
              <LinkComponent
                text={"Avaliable"}
                bgColor={"tertiary-100"}
                color={"tertiary-500"}
                borderColor={"tertiary-500"}
              />
            )}
          </EnvironmentInputRadio>

          {/* HABILITAMOS SELECCIÓN DE INPUT SI EL USUARIO ES CLIENTE */}
          <EnvironmentInputRadio
            type="radio"
            value={LIVE}
            name="environment"
            id={userData?.client ? LIVE : SANDBOX}
            checked={formData.environment === LIVE}
            onChange={handleChange}
            htmlFor={LIVE}
            title={LIVE}
            description={`You’re ready to conquer the world`}
          >
            {!userData?.client ? (
              <>
                {becomeCustomerSended ? (
                  <div onClick={() => showPendingError()}>
                    <LinkComponent
                      text={"Upgrade pending"}
                      icon={"icon-pending"}
                      bgColor={"bg-pending"}
                    />
                  </div>
                ) : (
                  <div onClick={onOpenBecomeCustomerModal}>
                    <LinkComponent
                      text={"Upgrade your plan"}
                      icon={"icon-spark"}
                      filled
                    />
                  </div>
                )}
              </>
            ) : userData?.client && formData.environment === LIVE ? (
              <LinkComponent
                text={"Selected"}
                icon={"icon-check"}
                bgColor={"tertiary-100"}
                filled
              />
            ) : (
              <LinkComponent
                text={"Avaliable"}
                bgColor={"tertiary-100"}
                color={"tertiary-500"}
                borderColor={"tertiary-500"}
              />
            )}
          </EnvironmentInputRadio>
        </div>
      </FormRequestSubscriptionInput.Content>
    </FormRequestSubscriptionInput>
  );
};

export default RegisterAppSection;
